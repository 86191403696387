@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}


.navbar-link {
  margin: 5px;
  text-decoration: none;
  color: grey;
  display: inline-block;
  position: relative;
  font-size: medium;
  transition: all ease-in-out 500ms;
}

.navbar-link:hover {
  color: black;
}