

.fade-in {
    color: black;
    animation: fadeInRight 1s ease-in-out;
}

.fade-in2 {
    color: black;
    animation: fadeInLeft 1s ease-in-out;
}

.header {
    font-size: 32px;
}
@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(300px);
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-300px);
    }
    to {
      opacity: 1;
    }
  }